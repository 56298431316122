import { Widgets } from './widgets/widgets.js';
import { DynamicTags } from './dynamic-tags/dynamic-tags.js';

jQuery(window).on('elementor/frontend/init', () => {

  /**
   * Widgets
   */
  Widgets()

  /**
   * DynamicTags
   */
  DynamicTags()
  
})
