export const initSlider = $element => {

  const config = getConfig($element)
  const $container = $element.find('.elementor-slides-wrapper')

  /**
   * In recent version of Elementor, swiper.js lib is loaded asynchronously
   * 
   * If Swiper is undefined, it means we use async mode
   * 
   * @see https://developers.elementor.com/experiment-optimized-asset-loading/ 
   */

  if( typeof Swiper === 'undefined' ) {

    const asyncSwiper = elementorFrontend.utils.swiper

    new asyncSwiper( $container, config ).then( 
      slider => initAnimation(slider, $container)
    )

  } else {
    
    const slider = new Swiper( $container, config )
    
    initAnimation(slider, $container)
  }

}

const getConfig = $element => {

  const config = JSON.parse(
    $element.attr('data-settings')
  )

  if( ! config ) return false;

  const options = {
    grabCursor           : true,
    initialSlide         : 0,
    slidesPerView        : 1,
    slidesPerGroup       : 1,
    loop                 : config.infinite === 'yes',
    speed                : config.transition_speed,
    effect               : config.transition,
    disableOnInteraction : config.pause_on_hover === 'yes'
  };

  const showArrows = 'arrows' === config.navigation || 'both' === config.navigation
  const pagination = 'dots' === config.navigation || 'both' === config.navigation

  if( showArrows ) {
    options.navigation = {
      prevEl: '.elementor-swiper-button-prev',
      nextEl: '.elementor-swiper-button-next'
    }
  }

  if( pagination ) {
    options.pagination = {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    }
  }
  
  if( options.loop === true )     options.loopedSlides = $element.find('.swiper-slide').length
  if( options.effect === 'fade' ) options.fadeEffect = { crossFade: true }

  return options;
}

const initAnimation = (slider, $container) => {

   const animation = $container.attr('data-animation')
    
   if( ! animation ) return;
   
   slider.on('slideChangeTransitionStart', () => {
    const $sliderContent = $container.find(".le-slide-content")
    $sliderContent.removeClass(`animated ${animation}`).hide()
  })

  slider.on('slideChangeTransitionEnd', () => {
    const $currentSlide = $container.find(".le-slide-content")
    $currentSlide.show().addClass(`animated ${animation}`)
  })

}
