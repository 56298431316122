
const dripTimer = () => {

  const $ = jQuery
  let $dripTimers

  // Check if inside editor
  const { elementorFrontend = {} } = window
  const isEditor = elementorFrontend.config
    && elementorFrontend.config.environmentMode
    && elementorFrontend.config.environmentMode.edit

  function timer() {

    // Query DOM once on frontend, every time in editor
    if (isEditor || !$dripTimers) {
      $dripTimers = $('.le-lesson-drip-timer-wrapper')
    }

    $dripTimers.each( function(){

      var timer_wrapper =  $(this),
        show_days = timer_wrapper.find('.days'),
        show_hours = timer_wrapper.find('.hours'),
        show_minutes = timer_wrapper.find('.minutes'),
        show_seconds = timer_wrapper.find('.seconds'),
        data = timer_wrapper.data('timer'),
        drip_time = data.driptime,
        show = data.show,
        round = data.round,
        now = new Date()

      now = Date.parse(now) / 1000

      var time_remaining = drip_time - now,
        seconds = Math.floor(time_remaining % 60),
        minutes = Math.floor(time_remaining / 60 % 60),
        hours = Math.floor(time_remaining / (60 * 60) % 24),
        days = Math.floor(time_remaining / (60 * 60 * 24))

      // round up days and hours if set
      if( show === 'days' && round === 'yes' && hours >= 12 ) {
        days += 1
      }
      if( show === 'hours' && round === 'yes' && minutes >= 30 ) {
        hours += 1
      }

      if( show === 'days' || show === 'all' ) {
        show_days.html( days + '<span> '+LE_Driptimer.time_labels.days+'</span>' )
      } else {
        show_days.remove()
      }
      if( show === 'hours' || show === 'all' ) {
        show_hours.html(hours + '<span> '+LE_Driptimer.time_labels.hours+'</span>')
      } else {
        show_hours.remove()
      }
      if( show === 'minutes' || show === 'all' ) {
        show_minutes.html(minutes + '<span> '+LE_Driptimer.time_labels.minutes+'</span>')
      } else {
        show_minutes.remove()
      }
      if( show === 'seconds' || show === 'all' ) {
        show_seconds.html(seconds + '<span> '+LE_Driptimer.time_labels.seconds+'</span>')
      } else {
        show_seconds.remove()
      }
    }) // end each
  }

  timer()
  setInterval( timer, 1000 )
}

export { dripTimer }
