import { initSlider } from './access-plans/access-plan-slider.js'
import { createSliderLegacy } from './access-plans/access-plan-slider-legacy.js'
import { circleInit } from './progress/progress.js'

const Widgets = () => {
	
  const addAction = elementorFrontend.hooks.addAction

  /**
   * Access Plan Slider
   */
  addAction('frontend/element_ready/le-course-access-plans.default', $scope => {
    LE_Widgets.course_access_plans.is_legacy === 'no'
      ? initSlider($scope)
      : createSliderLegacy()
  })

  /**
   * Course outline
   */
   addAction('frontend/element_ready/le-course-outline.default', $scope => {
    LLMS.OutlineCollapse.$outlines.length ? LLMS.OutlineCollapse.bind() : ''
    LLMS.OutlineCollapse.init()
  })

  /**
   * Course progress
   */
  addAction('frontend/element_ready/le-course-progress.default', $scope => {
    circleInit($scope)
  })
  
}

export { Widgets }
