/**
 * ElementorPro is no using the slick library anymore for the slider widget
 * for verison 2.7 and above
 * 
 * @see https://developers.elementor.com/elementor-2-7-moving-sliders-from-slick-to-swiper/
 */

var createSliderLegacy = () => {
 
	let $ = jQuery
	let d = document

	let container = d.getElementsByClassName('elementor-widget-le-course-access-plans')

	for (let i = container.length - 1; i >= 0; i--) {
		let sliderDiv = container[i].getElementsByClassName('elementor-slides')[0]

    if (sliderDiv !== undefined) {
      
      // Init slider (config is stored in a data-attribute)
      let sliderConfig = sliderDiv.getAttribute('data-slider_options')  
      sliderConfig = JSON.parse(sliderConfig)
      $(sliderDiv).slick(sliderConfig)

      // Animation of the content on change 
      let sliderAnimation = sliderDiv.getAttribute('data-animation')
      
      // Init content animation
      if (!sliderAnimation) return
      
      $(sliderDiv).on({
        beforeChange: (event, slick, currentSlide, nextSlide) => {
          
          // There is not build in function for set the transition, elementor use a custom class for that
          let $sliderContent = $(sliderDiv).find(".le-slide-content")
          $sliderContent.removeClass(`elementor-slide-content animated ${sliderAnimation}`).hide()

          // Not working (try to implement variable height)
          // let $nextSlide = $(slick.$slides.get(nextSlide)).find(".le-slide-content")
          // $(slick.$slides).height($nextSlide.outerHeight())
        },
        afterChange: (event, slick, currentSlide) => {
          
          // There is not build in function for set the transition, elementor use custom class for that
          let $currentSlide = $(slick.$slides.get(currentSlide)).find(".le-slide-content")
          $currentSlide.show().addClass(`elementor-slide-content animated ${sliderAnimation}`)
        } 
      })
    }

	}
}

export { createSliderLegacy }
