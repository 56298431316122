import { dripTimer } from './driptimer/driptimer.js';

var DynamicTags = () => {

  /**
   * Drip Timer
   */
  dripTimer()

}

export { DynamicTags }
