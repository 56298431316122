let circleInit = ($scope) => {

    let d = document
        
    let elementID     = $scope[0].getAttribute('data-id')
    let widget        = d.getElementsByClassName(`elementor-element-${elementID}`)[0]
    
    if(!widget.getElementsByClassName('le-circle-progress-circle')[0]) return;

    let widgetWrapper = widget.getElementsByClassName('le-course-progress-wrapper')[0] 

    let color1  = widgetWrapper.getAttribute('data-color-one')
    let color2  = widgetWrapper.getAttribute('data-color-two')
    let percent = widgetWrapper.getAttribute('data-percent')
    let size    = widgetWrapper.getAttribute('data-size')

    let gradient = new ConicGradient({
        stops: `${color1} 0, ${color2} ${percent}, rgba(0, 0, 0, 0) 0`, 
        size: size
    })

    let container = widget.getElementsByClassName('le-circle-progress-inner')[0]
    container.setAttribute('style', "background-image: url('" + gradient.dataURL + "')")
}

export { circleInit }
